import { createRouter, createWebHistory } from 'vue-router'
import IndexSeven from '../pages/IndexSeven'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexSeven
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {


  if (window.top !== window.self) {
    alert('Akses ditolak: Aplikasi tidak dapat disematkan dalam iframe.');
    window.top.location = window.location.href; // Redirect keluar dari iframe
  } else {
    next();
  }


});

export default router
